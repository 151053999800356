<template>
  <Dialog
    v-if="true"
    :name="name"
    width="728px"
    class-own="dialog-delete-quota"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">
        Подтверждение операции
      </div>
    </template>

    <div>
      <div v-if="!dialogDataCount" class="dialog-delete-quota__message">
        Вы действительно хотите удалить квоту?
      </div>

      <div v-else>
        <p class="alert-title m-b-16">⚠️ ВНИМАНИЕ! ⚠️</p>

        <div class="alert-text">
          <div class="alert-delete-item">
            По данной квоте было выдано {{ countSlot }}, предупредите водителей
            об изменениях или не удаляйте квоту.
          </div>
        </div>
        <div class="m-t-33 m-b-33">
          <iq-check-box
            v-model="confirmDelete"
            title="Подтверждаю условия удаления квоты"
          />
        </div>
      </div>

      <div class="df jc-sb">
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-right: 4px; cursor: pointer"
          text-style="color: #000000; font-weight: 500"
          title="Нет"
          :disabled="isLoading"
          @onClick="handleRemoveQuota(false)"
        />
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white; cursor: pointer"
          text-style="color: white; font-weight: 500"
          :title="titleDelete"
          :loading="isLoading"
          :disabled="countSlot > 0 && !confirmDelete"
          @onClick="handleRemoveQuota(true)"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import { DELETE_QUOTA, GET_IS_LOADING_QUOTAS } from '@/views/home/store/actions'
import { HOME_DELETE_QUOTA } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqCheckBox from '@/views/manager/components/mobile/components/modal/IqCheckBox'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'

export default {
  name: 'HomeDeleteQuota',
  components: { IqMobileButton, Dialog, IqCheckBox },
  data() {
    return { name: HOME_DELETE_QUOTA, confirmDelete: false }
  },
  computed: {
    ...mapGetters({ isLoading: GET_IS_LOADING_QUOTAS }),
    dialogDataId() {
      return this.getDialog(this.name)?.data?.id
    },
    dialogDataCount() {
      return this.getDialog(this.name)?.count
    },
    countSlot() {
      if (this.dialogDataCount === 1) {
        return `${this.dialogDataCount} таймслот`
      }
      if (this.dialogDataCount > 1 && this.dialogDataCount < 5) {
        return `${this.dialogDataCount} таймслота`
      }
      if (this.dialogDataCount >= 5) {
        return `${this.dialogDataCount} таймслотов`
      }

      return `${this.dialogDataCount} таймслот`
    },
    titleDelete() {
      return !this.dialogDataCount ? 'Да' : 'Подтверждаю удаление'
    },
  },
  methods: {
    ...mapActions({ deleteQuota: DELETE_QUOTA }),
    handleRemoveQuota(isRemove = false) {
      if (isRemove) {
        this.$message({
          type: 'info',
          message: 'Отправлен запрос на удаление квоты',
          customClass: 'driver-message',
        })
        this.deleteQuota({ quota_id: this.dialogDataId })
      } else {
        this.$message({
          type: 'info',
          message: 'Удаление квоты не подтвержденно',
          customClass: 'driver-message',
        })
      }
      this.setDialog({ name: this.name }, false)
    },
  },
}
</script>

<style lang="sass">
.home-delete-quota
  .el-dialog__header
    background: #F56C6C!important
  .alert-title
    font-weight: bold
    font-size: 1.8em
    margin: 0 0 24px 0
    @media only screen and (max-width: 768px)
      font-size: 1.8em
      line-height: 130% !important
    @media only screen and (max-width: 456px)
      font-size: 1.8em
      line-height: 130% !important
      margin: 0px 0px 8px 0px!important
    .alert-text
      font-size: 18px
      margin-bottom: 18px
    @media only screen and (max-width: 768px)
      font-size: 16px!important
    @media only screen and (max-width: 456px)
      font-size: 14px!important

.dialog-delete-quota
  &__message
    color: #303133
    margin-top: 16px
    margin-bottom: 24px
    font-size: 16px
  .el-button + .el-button
    margin-left: 35px
</style>
